import React from "react"
import { Link } from "gatsby"
import { mdiArrowRight } from "@mdi/js"
import css from "@styled-system/css"

import { Button, ColorMode, Flex, Heading, Wrapper } from "components"

const QuoteSection = ({ data, color, id }) => {
  return (
    <>
      <Wrapper id={id}>
        <ColorMode mode="dark">
          <Flex
            flexDirection={["column", null, "row"]}
            justifyContent="space-between"
            alignItems="center"
            p="layout.4"
            borderRadius={[16, null, 9999]}
            bg={color}
          >
            <Heading
              size={800}
              ml="layout.2"
              mr="layout.4"
              mb={["layout.2", null, 0]}
            >
              Interested in {data.serviceName}?
            </Heading>
            {/* Button */}
            <Button
              as={Link}
              to={"/contact/" + data.filter}
              iconAfter={mdiArrowRight}
              intent="secondary"
              appearance="default"
              height={[40, 48]}
              css={css({
                color: "background",
              })}
            >
              Request a Proposal
            </Button>
          </Flex>
        </ColorMode>
      </Wrapper>
    </>
  )
}

export default QuoteSection
