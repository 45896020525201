import React from "react"

// Project components
import { Box, FlexGrid, Heading, ServiceCard, Wrapper } from "components"

const OtherServicesSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {(data.title || data.services) && (
        <Wrapper id={id}>
          {data.title && (
            <Heading size={800} mb={["layout.4", null, "layout.6"]}>
              {data.title}
            </Heading>
          )}

          {data.services && (
            <Box mx={["-8px", "-16px"]}>
              <FlexGrid
                content={data.services.map(service => ({
                  width: ["100%", "33%"],
                  children: (
                    <ServiceCard
                      image={service.image}
                      title={service.title}
                      color={service.color}
                      to={service.to}
                    />
                  ),
                }))}
                gutterX={[2, 3, 16]}
                gutterY={[2, 4]}
              />
            </Box>
          )}
        </Wrapper>
      )}
    </>
  )
}

export default OtherServicesSection
