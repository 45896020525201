import React from "react"
import { Link as ScrollLink } from "react-scroll"
import { mdiArrowDown } from "@mdi/js"

import { Box, Button, Heading, RichText, Split } from "components"

const CONTENT_START_ID = "service-features"

const IntroSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {(data.title || data.bodyContent || data.color) && (
        <Split id={id} image={data.image}>
          {/* Title */}
          {data.title && (
            <Heading size={900}>
              Service <br />
              <Box as="span" color={data.color}>
                {data.title}
              </Box>
            </Heading>
          )}

          {/* Content */}
          {data.bodyContent && (
            <RichText
              content={{
                html: data.bodyContent,
              }}
              size={500}
              mt={["layout.4", null, null, "layout.8"]}
            />
          )}
          <ScrollLink smooth={true} to={CONTENT_START_ID}>
            <Button
              as="div"
              iconAfter={mdiArrowDown}
              intent="primary"
              appearance="primary"
              height={[40, 48]}
              mt="layout.5"
            >
              Learn More
            </Button>
          </ScrollLink>
        </Split>
      )}
    </>
  )
}

export default IntroSection
