import React from "react"
import Image from "gatsby-image/withIEPolyfill"
import { Link } from "gatsby"
import { mdiDownload, mdiArrowRight } from "@mdi/js"

// Project components
import {
  AspectRatio,
  Box,
  Button,
  Flex,
  FlexGrid,
  Heading,
  RichText,
  Wrapper,
} from "components"

const Sign = ({ title, image, downloadLink }) => (
  <Flex>
    <Flex flexDirection="column" flex={1}>
      {title && (
        <Heading size={100} fontWeight="bold" textAlign="center" p="layout.2">
          {title}
        </Heading>
      )}
      <AspectRatio ratio="4:5" borderRadius={8} bg="wash">
        {image && image.fluid && (
          <Image
            fluid={image.fluid}
            alt={image.alt}
            objectFit="contain"
            objectPosition="center"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "75%",
              height: "75%",
              transform: "translate(-50%, -50%)",
            }}
          />
        )}
      </AspectRatio>

      <Flex justifyContent="center" mt="-20px">
        <Button
          iconAfter={mdiDownload}
          intent="default"
          appearance="primary"
          height={40}
          as="a"
          href={downloadLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          Download
        </Button>
      </Flex>
    </Flex>
  </Flex>
)

const SignageSection = ({ data, id }) => {
  return (
    <>
      {/* We only render the section if it has props set */}
      {(data.title || data.bodyContent || data.signs) && (
        <Wrapper id={id}>
          <Box
            mx={["-24px", "-64px"]}
            px={["layout.3", "layout.6"]}
            py="layout.6"
            borderRadius={[0, null, null, 16]}
            bg="wash"
          >
            <FlexGrid
              content={[
                {
                  width: ["100%", "50%", "33.333%"],
                  children: (
                    <>
                      <Heading size={800}>{data.title}</Heading>
                      <RichText
                        content={{
                          html: data.bodyContent,
                        }}
                        size={500}
                        mt={["layout.2", "layout.4"]}
                        color="alt"
                      />
                      <Box mt="layout.4">
                        <Button
                          as={Link}
                          to="/signage/"
                          iconAfter={mdiArrowRight}
                          intent="default"
                          appearance="default"
                          height={40}
                        >
                          All Signage
                        </Button>
                      </Box>
                    </>
                  ),
                },
                ...data.signs.map(sign => ({
                  width: ["50%", null, "33.333%"],
                  children: <Sign {...sign} />,
                })),
              ]}
              gutterX={[2, 6, 6, 8]}
              gutterY={[6, 8]}
            />
          </Box>
        </Wrapper>
      )}
    </>
  )
}

export default SignageSection
