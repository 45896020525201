import React from "react"
import Image from "gatsby-image/withIEPolyfill"

// Project components
import {
  AspectRatio,
  Box,
  Flex,
  FlexGrid,
  Heading,
  Text,
  Wrapper,
} from "components"

const Item = ({ label, icon }) => (
  <Box mt="layout.2">
    <Flex alignItems="center">
      <Box width={32}>
        <AspectRatio ratio="1:1">
          {icon && icon.fluid && (
            <Image
              fluid={icon.fluid}
              alt={icon.alt}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              objectFit="cover"
              objectPosition="center"
            />
          )}
        </AspectRatio>
      </Box>
      <Box flex={1} ml="layout.2">
        <Text>{label}</Text>
      </Box>
    </Flex>
  </Box>
)

const FeaturesSection = ({ data, id }) => {
  return (
    <>
      <Wrapper id={id}>
        <FlexGrid
          content={[
            data.useCases && {
              width: ["100%", null, "50%"],
              children: (
                <Box>
                  <Heading size={800}>Use Cases</Heading>
                  {data.useCases.map((useCase, index) => (
                    <Item
                      label={useCase.use_case}
                      icon={useCase.icon}
                      key={"usecase" + index}
                    />
                  ))}
                </Box>
              ),
            },
            data.features && {
              width: ["100%", null, "50%"],
              children: (
                <Box>
                  <Heading size={800}>Features</Heading>
                  {data.features.map((feature, index) => (
                    <Item
                      label={feature.feature}
                      icon={feature.icon}
                      key={"feature" + index}
                    />
                  ))}
                </Box>
              ),
            },
          ]}
        />
      </Wrapper>
    </>
  )
}

export default FeaturesSection
