import React from "react"
import { graphql } from "gatsby"

import { SEO } from "components"

// Page sections
import IntroSection from "./_introSection"
import BinsSection from "./_binsSection"
// import StatSection from "./_statSection"
import SignageSection from "./_signageSection"
import FeaturesSection from "./_featuresSection"
// import RulesSection from "./_rulesSection"
import QuoteSection from "./_quoteSection"
import OtherServicesSection from "./_otherServicesSection"

const ServiceTemplate = ({ data }) => {
  if (data) {
    return (
      <>
        <SEO
          title={
            data.page.data.name && data.page.data.name.text + " | Services"
          }
          description={
            data.page.data.description && data.page.data.description.text
          }
          image={data.page.data.main_image && data.page.data.main_image.url}
          imageAlt={data.page.data.main_image && data.page.data.main_image.alt}
        />
        {/* ============ INTRO ============ */}
        <IntroSection
          id="service-intro"
          data={{
            image: data.page.data.main_image,
            title: data.page.data.name && data.page.data.name.text,
            color: data.page.data.color,
            bodyContent:
              data.page.data.description && data.page.data.description.html,
          }}
        />
        {/* ============ FEATURES ============ */}
        <FeaturesSection
          id="service-features"
          data={{
            features: data.page.data.features,
            useCases: data.page.data.use_cases,
          }}
        />
        {/* ============ STAT ============ */}
        {/* <StatSection
          id="service-stat"
          data={{
            statValue: 2999900,
            statDescription: `KG ${data.page.data.name &&
              data.page.data.name.text.toLowerCase()} diverted`,
            color: data.page.data.color,
          }}
        /> */}
        {/* ============ QUOTE ============ */}
        <QuoteSection
          data={{
            serviceName: data.page.data.name && data.page.data.name.text,
            filter: "#quote",
          }}
          color={data.page.data.color}
        />
        {/* ============ RULES ============ */}
        {/* <RulesSection
          data={{
            title:
              data.page.data.name &&
              data.page.data.name.text + " made easy with basic rules",
          }}
        /> */}
        {/* ============ SIGNAGE ============ */}
        <SignageSection
          id="service-signage"
          data={{
            title: "Signage",
            bodyContent:
              data.page.data.signage_title &&
              data.page.data.signage_title.text &&
              `<p>` + data.page.data.signage_title.text + "</p>",
            signs:
              data.signs &&
              data.signs.nodes &&
              data.signs.nodes
                .filter(sign => {
                  let found = false
                  sign.data.service_types &&
                    sign.data.service_types.forEach(type => {
                      if (
                        type.service_type &&
                        type.service_type.uid === data.page.uid
                      ) {
                        found = true
                      }
                    })
                  return found
                })
                .slice(0, 5)
                .map(sign => ({
                  title: sign.data.title && sign.data.title.text,
                  image:
                    sign.data.sign && sign.data.sign.fluid && sign.data.sign,
                  downloadLink: sign.data.download && sign.data.download.url,
                })),
          }}
        />
        {/* ============ OVERRIDE BINS ============ */}
        {data.page.data.bins && data.page.data.bins[0] && (
          <BinsSection
            id="service-bins"
            data={{
              title:
                "Bins Available for " +
                (data.page.data.name && data.page.data.name.text),
              bins: data.page.data.bins.slice(0, 3).map(({ bin }) => {
                if (bin.document) {
                  return {
                    image:
                      bin.document.data.image &&
                      bin.document.data.image.fluid &&
                      bin.document.data.image,
                    name: bin.document.data.name && bin.document.data.name.text,
                    description:
                      bin.document.data.description &&
                      bin.document.data.description.text,
                    greatFor: bin.document.data.great_for,
                    dimensions: {
                      width: bin.document.data.width,
                      depth: bin.document.data.depth,
                      height: bin.document.data.height,
                    },
                    serviceTypes: bin.document.data.service_types,
                  }
                }
              }),
              filter: data.page.uid,
            }}
          />
        )}
        {/* ============ GENERAL BINS ============ */}
        {(!data.page.data.bins || !data.page.data.bins[0]) && (
          <BinsSection
            id="service-bins"
            data={{
              title:
                "Bins available for " +
                (data.page.data.name && data.page.data.name.text),
              bins: data.bins.nodes
                .filter(binData => {
                  let found = false
                  if (binData.data.service_types) {
                    binData.data.service_types.forEach(({ service_type }) => {
                      if (service_type.uid === data.page.uid) {
                        found = true
                      }
                    })
                  }
                  return found
                })
                .slice(0, 3)
                .map(binData => {
                  return {
                    image:
                      binData.data.image &&
                      binData.data.image.fluid &&
                      binData.data.image,
                    name: binData.data.name && binData.data.name.text,
                    description:
                      binData.data.description && binData.data.description.text,
                    greatFor: binData.data.great_for,
                    dimensions: {
                      width: binData.data.width,
                      depth: binData.data.depth,
                      height: binData.data.height,
                    },
                    serviceTypes: binData.data.service_types,
                  }
                }),
              filter: data.page.uid,
            }}
          />
        )}
        {/* ============ MORE SERVICES ============ */}
        {data.allPrismicService && data.allPrismicService.nodes && (
          <OtherServicesSection
            id="service-otherServices"
            data={{
              title: "More Services",
              services: data.allPrismicService.nodes.map(service => {
                return {
                  image:
                    service.data.main_image &&
                    service.data.main_image.fluid &&
                    service.data.main_image,
                  title: service.data.name && service.data.name.text,
                  color: service.data.color,
                  to: "/services/" + service.uid + "/",
                }
              }),
            }}
          />
        )}
        }
      </>
    )
  } else {
    return null
  }
}

export const query = graphql`
  query ServiceQuery($uid: String!) {
    page: prismicService(uid: { eq: $uid }) {
      uid
      data {
        main_image {
          url
          fluid(maxWidth: 1200) {
            ...GatsbyPrismicImageFluid
          }
          alt
        }
        name {
          text
        }
        description {
          html
          text
        }
        color
        use_cases {
          icon {
            fluid(maxWidth: 48) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          use_case
        }
        features {
          icon {
            fluid(maxWidth: 48) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          feature
        }
        signage_title {
          text
        }
        bins {
          bin {
            document {
              ... on PrismicBin {
                id
                data {
                  image {
                    fluid(maxWidth: 600) {
                      src
                    }
                    alt
                  }
                  name {
                    text
                  }
                  description {
                    text
                  }
                  height
                  width
                  depth
                  great_for
                  service_types {
                    service_type {
                      uid
                      document {
                        ... on PrismicService {
                          uid
                          data {
                            name {
                              text
                            }
                            color
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    signs: allPrismicSign(sort: { fields: data___title___text, order: ASC }) {
      nodes {
        data {
          title {
            text
          }
          sign {
            fluid(maxWidth: 512) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          download {
            url
          }
          service_types {
            service_type {
              uid
            }
          }
        }
      }
    }
    bins: allPrismicBin(sort: { fields: data___name___text, order: DESC }) {
      nodes {
        data {
          image {
            fluid(maxWidth: 600) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          name {
            text
          }
          description {
            text
          }
          height
          width
          depth
          great_for
          service_types {
            service_type {
              uid
              document {
                ... on PrismicService {
                  uid
                  data {
                    name {
                      text
                    }
                    color
                  }
                }
              }
            }
          }
        }
      }
    }
    allPrismicService(filter: { uid: { ne: $uid } }) {
      nodes {
        uid
        data {
          main_image {
            fluid(maxWidth: 640) {
              ...GatsbyPrismicImageFluid
            }
            alt
          }
          name {
            text
          }
          color
        }
      }
    }
  }
`

export default ServiceTemplate
